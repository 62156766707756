<template>
  <div>
    <div class="download" v-b-tooltip.hover title="下載" @click="downloadC">
      <i class="fas fa-download"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: 'downloadbutton',
  methods: {
    downloadC () {
      this.$emit('compoDownloadEvent')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/customVariables.scss';
.download{
  margin-left: 10px;
  width: 45px;
  height: 30px;
  color: #fff;
  background-color: $acme-blue-6;
  border-radius: 5px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
@media screen and (max-width: 375px) {
  .download{
    margin-top: 10px;
    width: 100px;
  }
}
</style>
